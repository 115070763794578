import { Component, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal, NgbModal, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { DefaultIfEmptyPipe } from '../../../pipes/defaultEmpty.pipe';
import { PersonalInformationTabComponent } from './personal-information/personal-information-tab.component';
import { DocumentsTabComponent } from './documents-tab/documents-tab.component';
import { CreditsTabComponent } from './credits-tab/credits-tab.component';
import { CustomersInfoTransUnionComponent } from '../../../../administrative/customers/components/modals/customers-info-trans-union/customers-info-trans-union.component';
import { CustomerData } from '../../../../administrative/customers/interfaces/customer-credit-details.interface';

@Component({
      selector: 'app-customer-info-show-modal',
      templateUrl: './customer-info-show-modal.component.html',
      standalone: true,
      imports: [
            NgbModalModule,
            CommonModule,
            FormsModule,
            ReactiveFormsModule,
            DefaultIfEmptyPipe,
            PersonalInformationTabComponent,
            DocumentsTabComponent,
            CreditsTabComponent
      ]
})
export class CustomerInfoShowModalComponent {
      @Input() customerInfo!: CustomerData;
      @Input() datosDeshabilitados: boolean = true;
      @Input() creditRequestId!: number;
      @Input() title: string = 'Solicitud de crédito';
      isAdmin: boolean = false;
      tabActiva = 'informacionPersonal';


      constructor(
            public activeModal: NgbActiveModal,
            private modalService: NgbModal,
      ) { }

      ngOnInit() {
            console.log(this.customerInfo);
            console.log(this.creditRequestId);
            console.log(this.datosDeshabilitados);
      }

      /**
       * Cambia la pestaña activa en el componente.
       * @param {string} pestana - El nombre de la pestaña a activar.
       */
      cambiarPestana(pestana: string) {
            this.tabActiva = pestana;
      }

      /**
       * Abre un modal con la información financiera del cliente.
       * @param {any} customer - El objeto cliente cuya información financiera se mostrará.
       */
      public openFinancialInfoModal(customer: any) {
            const modalRef = this.modalService.open(CustomersInfoTransUnionComponent, {
                  size: 'lg'
            });
            modalRef.componentInstance.customerId = customer.id;
      }
}
