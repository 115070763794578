import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { DefaultIfEmptyPipe } from '../../../../pipes/defaultEmpty.pipe';
import { toggleLoader } from '../../../../helpers/functions-helper.service';
import { CreditRequestService } from '../../../../../administrative/credits-requests/services/credit-request.service';
import { LoaderService } from '../../../../services/loader.service';
import { ModalServiceAlert } from '../../../modal-alert/service/modal-alert.service';
import { CreditsDocumentsResponseData } from '../../../../../administrative/credits-requests/interfaces/credits-documents-interface';
import { CustomerData } from '../../../../../administrative/customers/interfaces/customer-credit-details.interface';
@Component({
      selector: 'app-documents-tab',
      templateUrl: './documents-tab.component.html',
      standalone: true,
      imports: [
            NgbModalModule,
            CommonModule,
            FormsModule,
            ReactiveFormsModule,
            DefaultIfEmptyPipe,
      ]
})
export class DocumentsTabComponent {
      @Input() creditRequestId!: number;
      @Input() customerInfo!: CustomerData;
      documentsList: CreditsDocumentsResponseData[] = [];


      constructor(
            public activeModal: NgbActiveModal,
            private CreditRequestService: CreditRequestService,
            private loaderService: LoaderService,
            private alertService: ModalServiceAlert,
      ) { }

      /**
       * Inicializa el componente y obtiene los documentos de crédito.
       */
      ngOnInit(): void {
            this.getDocumentsCredits();
      }

      /**
       * Sube un documento al servidor.
       * @param {HTMLInputElement} fileInput - El elemento de entrada de archivo que contiene el documento a subir.
       */
      subirDocumento(fileInput: any) {
            const file = fileInput.files[0];

            if (!file) {
                  this.alertService.openModal(
                        'Error',
                        'No se seleccionó un archivo.',
                        'error'
                  );
                  return;
            }
            toggleLoader(this.loaderService, true, 'Subiendo documento...');
            const customerId = this.creditRequestId;
            const formdata = new FormData();
            formdata.append('file', file);
            formdata.append('creditRequestId', customerId.toString());
            formdata.append('customerId', this.customerInfo.customer.id.toString());
            this.CreditRequestService.saveCustomerDocument(formdata).subscribe({
                  next: (response) => {
                        toggleLoader(this.loaderService, false);
                        if (response.ok) {
                              this.alertService.openModal(
                                    'Éxito',

                                    'Se subió el documento correctamente.',
                                    'success'
                              );
                              fileInput.value = '';
                              this.getDocumentsCredits();
                        }
                  },
                  error: (error) => {
                        toggleLoader(this.loaderService, false);
                        this.alertService.openModal('Error', 'Ocurrio un error al subir el documento', 'error');
                  }
            });
      }

      /**
       * Descarga un archivo del servidor.
       * @param {string} file - El nombre del archivo a descargar.
       */
      downloadFile(file: string) {
            toggleLoader(this.loaderService, true, 'Descargando archivo...');
            this.CreditRequestService.downloadFile(file).subscribe({
                  next: (response: any) => {
                        const blob = new Blob([response], { type: response.type });
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement('a');
                        a.href = url;
                        a.download = file;
                        a.click();
                        window.URL.revokeObjectURL(url);
                        toggleLoader(this.loaderService, false);
                  },
                  error: (error) => {
                        this.alertService.openModal('Error', 'Ocurrió un error al descargar el archivo.', 'error');
                        toggleLoader(this.loaderService, false);
                  }
            });
      }

      /**
       * Obtiene los documentos de crédito del cliente.
       */
      getDocumentsCredits() {
            toggleLoader(this.loaderService, true);
            const creditRequestId = this.creditRequestId;
            this.CreditRequestService.getCustomerDocuments(this.customerInfo.customer.id).subscribe(
                  (response) => {
                        toggleLoader(this.loaderService, false);
                        this.documentsList = response.data;
                  },
                  (error) => {
                        toggleLoader(this.loaderService, false);
                        this.alertService.openModal(
                              'Error',
                              'Ocurrió un error al obtener los documentos. Por favor, inténtalo nuevamente. ' + error,
                              'error'
                        );
                  }
            );
      }

}
