import { CurrencyPipe } from '@angular/common';
import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-details-credit',
  standalone: true,
  imports: [
    CommonModule,
    CurrencyPipe
    
  ],
  templateUrl: './details-credit.component.html',
  styleUrl: './details-credit.component.scss'
})
export class DetailsCreditComponent {

  @Input() item!: any;

}
