<div class="row g-3 w-100">
    <!-- Tarjeta de Intereses Corrientes -->
    <div class="col-md-4 mb-3">
        <div class="card h-100">
            <div class="card-body">
                <div class="d-flex justify-content-between mb-2">
                    <strong>Saldo Intereses Corrientes:</strong>
                    <span class="text-success">{{ item.currentInterestBalance | currency:'$':'symbol':'1.0-0' }}</span>
                </div>
                <div class="d-flex justify-content-between">
                    <strong>Días Corrientes:</strong>
                    <span>{{ item.currentDays }}</span>
                </div>
            </div>
        </div>
    </div>

    <!-- Tarjeta de Intereses de Mora -->
    <div class="col-md-4 mb-3">
        <div class="card h-100">
            <div class="card-body">
                <div class="d-flex justify-content-between mb-2">
                    <strong>Saldo Intereses de Mora:</strong>
                    <span class="text-success">{{( item.BalanceOfInterestInArrears | currency:'$':'symbol':'1.0-0' ) || 'No disponible'  }}</span>
                </div>
                <div class="d-flex justify-content-between mb-2">
                    <strong>Pago interés corrientes:</strong>
                    <span class="text-success">{{( item.paymentOfCurrentInterest | currency:'$':'symbol':'1.0-0' ) || 'No disponible'  }}</span>
                </div>
                <div class="d-flex justify-content-between">
                    <strong>Días en Mora:</strong>
                    <span>{{ item.daysInArrears }}</span>
                </div>
            </div>
        </div>
    </div>

    <!-- Tarjeta de Tasa y Saldos -->
    <div class="col-md-4">
        <div class="card h-100">
            <div class="card-body">
                <div class="d-flex justify-content-between mb-2">
                    <strong>Tasa de Mora Vigente:</strong>
                    <span>{{ item.currentDeliquencyRate }}%</span>
                </div>
                <div class="d-flex justify-content-between mb-2">
                    <strong>Saldo Capital:</strong>
                    <span class="text-success">{{ item.capitalBalance | currency:'$':'symbol':'1.0-0' }}</span>
                </div>
                <div class="d-flex justify-content-between">
                    <strong>Saldo Total:</strong>
                    <span class="text-success">{{ item.totalBalance | currency:'$':'symbol':'1.0-0' }}</span>
                </div>
            </div>
        </div>
    </div>

    <!-- Tarjeta de Fechas de Pago -->
    <div class="col-md-4">
        <div class="card h-100">
            <div class="card-body">
                <div class="d-flex justify-content-between mb-2">
                    <strong>Fecha Pago Cliente:</strong>
                    <span>{{ (item.datePayment | date:'dd/MM/yyyy') || 'No disponible'  }}</span>
                </div>
                <div class="d-flex justify-content-between">
                    <strong>Días de Pago:</strong>
                    <span>{{ (item.payDays || 'No disponible') }}</span>
                </div>
            </div>
        </div>
    </div>

    <!-- Tarjeta de Estado e Incidencia -->
    <div class="col-md-4">
        <div class="card h-100">
            <div class="card-body">
                <div class="d-flex justify-content-between mb-2">
                    <strong>Estado Mora:</strong>
                    <span class="badge" [ngClass]="{'bg-danger': item.moraStatus === 'En mora', 'bg-purple': item.moraStatus !== 'En mora'}">
                        {{ item.moraStatus }}
                    </span>
                </div>
                <div class="d-flex justify-content-between">
                    <strong>Incidencia:</strong>
                    <span>
                        <i class="fa-solid" [ngClass]="item.incidence ? 'fa-check-circle text-success' : 'fa-times-circle text-danger'"></i>
                        {{ item.incidence ? 'Si' : 'No' }}
                    </span>
                </div>
                <div class="d-flex justify-content-between">
                    <strong>Proveedor:</strong>
                    <span>
                        {{ item.shopPaymentName }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>
