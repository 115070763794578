@if(isLoading){
    <div class="d-flex justify-content-center m-5">
      <app-loader-wait></app-loader-wait>
    </div>
}@else {
    <table class="table mt-2">
        <thead>
            <tr>
                <th>Obligación</th>
                <th># de orden</th>
                <th>Fecha de entrega</th>
                <th>Fecha fin</th>
                <th>Valor del crédito</th>
                <th>Pago Interés mora</th>
                <th>Pago capital</th>
                <th>Abono Total</th>
                <th>Estado</th>
                <th>Mas información</th>
            </tr>
        </thead>
        <tbody>
            <!-- si hay registros -->
            <ng-container *ngIf="historyCreditsCustomerList && historyCreditsCustomerList.length > 0;">
                <ng-container *ngFor="let item of historyCreditsCustomerList; let i = index">
                    <tr>
                        <td>{{item.codePay }}</td>
                        <td>{{item.orderNumber }}</td>
                        <td>{{ item.deliveryDate || 'No disponible' }}</td>
                        <td>{{item.endDate || 'No disponible'}}</td>
                        <td>{{item.valueCredit | currency:'$':'symbol':'1.0-0'}}</td>
                        <td>{{item.paymentOfInterestInArrears | currency:'$':'symbol':'1.0-0' }}</td>
                        <td>{{item.paymentOfCapital | currency:'$':'symbol':'1.0-0'}}</td>
                        <td>{{ item.totalCredit | currency:'$':'symbol':'1.0-0' }}</td>
                        <td>
                            <span class="badge badge-{{ 
                                item.creditStatus === 'Sin confirmar' || item.creditStatus === 'Pendiente pago' ? 'warning' : 
                                item.creditStatus === 'Pendiente entrega' || item.creditStatus === 'Anulado' ? 'danger' : 
                                'success' 
                            }}">
                                {{ item.creditStatus }}
                            </span>
                        </td>
                        <td>
                            <button class="btn btn-sm  btn-outline-primary btn-outline-info" (click)="toggleDetails(i)">
                                <i class="fa-solid" [ngClass]="expandedRows[i] ? 'fa-chevron-up' : 'fa-chevron-down'"></i>
                            </button>
                        </td>
                    </tr>
                    <tr *ngIf="expandedRows[i]">
                        <td colspan="10">
                            <div class="p-3 bg-light rounded">
                                <h5>Detalle del crédito</h5>
                                <div class="row g-3 mt-2">
                                    <div class="d-flex flex-wrap">
                                        <app-details-credit [item]="item"></app-details-credit>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </ng-container>
            </ng-container>
    
            <!-- si no hay registros -->
            <ng-container *ngIf="!(historyCreditsCustomerList && historyCreditsCustomerList.length > 0)">
                <tr class="text-center">
                    <td colspan="100%" scope="row">
                        <i class="fas fa-info-circle"></i>
                        No se encontraron registros.
                    </td>
                </tr>
            </ng-container>
        </tbody>
    </table>  
}   
