import { style } from '@angular/animations';
import { CommonModule } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { CreditWithArrearInterface } from "../../../../../administrative/credits/interfaces/credits-with-arrears-interface";
import { toggleLoader } from "../../../../helpers/functions-helper.service";
import { ModalServiceAlert } from "../../../modal-alert/service/modal-alert.service";
import { LoaderService } from "../../../../services/loader.service";
import { CreditRequestService } from "../../../../../administrative/credits-requests/services/credit-request.service";
import { DefaultIfEmptyPipe } from "../../../../pipes/defaultEmpty.pipe";
import { CustomerData } from "../../../../../administrative/customers/interfaces/customer-credit-details.interface";
import { DetailsCreditComponent } from "./components/details-credit/details-credit.component";
import { LoaderWaitComponent } from '../../loader-wait/loader-wait.component';
import { finalize } from 'rxjs';

@Component({
      selector: 'app-credits-tab',
      templateUrl: './credits-tab.component.html',
      standalone: true,
      styles: [
            `
            .btn-outline-info:hover i {
                  color:rgb(255, 255, 255) !important;
            }

            
            `
      ],
      imports: [
            CommonModule,
            DefaultIfEmptyPipe,
            DetailsCreditComponent,
            LoaderWaitComponent
      ]
})
export class CreditsTabComponent implements OnInit {
      @Input() customerInfo!: CustomerData;
      historyCreditsCustomerList!: any[];
      expandedRows: boolean[] = [];
      isLoading: boolean = false;

      constructor(
            private loaderService: LoaderService,
            private alertService: ModalServiceAlert,
            private creditRequestService: CreditRequestService,
      ) { }

      /**
       * Inicializa el componente y obtiene el historial de créditos.
       */
      ngOnInit(): void {
            this.getHistoryCredits();
      }

      /**
       * Obtiene el historial de créditos del cliente.
       * Muestra un loader mientras se realiza la petición.
       * En caso de éxito, actualiza la lista de créditos.
       * En caso de error, muestra un mensaje de error.
       */
      getHistoryCredits(): void {
            this.isLoading = true;
            const customerInfoId = this.customerInfo.customer.id;
            toggleLoader(this.loaderService, true);
            this.creditRequestService.getHistoryCreditsCustomers(customerInfoId)
            .pipe(
                  finalize(() => {
                        toggleLoader(this.loaderService, false);
                        this.isLoading = false;
                  })
            )
            .subscribe(
                  (response) => {
                        this.historyCreditsCustomerList = response.data;
                        console.log('this.historyCreditsCustomerList', this.historyCreditsCustomerList);
                        this.expandedRows = new Array(this.historyCreditsCustomerList.length).fill(false);
                  },
                  (error) => {
                        this.alertService.openModal(
                              'Error',
                              'Ocurrió un error al obtener los créditos. Por favor, inténtalo nuevamente.',
                              'error'
                        );
                  }
            );
      }

      /**
       * Alterna la visibilidad de los detalles de una fila
       * @param index Índice de la fila a expandir/contraer
       */
      toggleDetails(index: number) {
            this.expandedRows[index] = !this.expandedRows[index];
      }
}