import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { CustomerInterface, IADescription } from '../../../../../administrative/customers/interfaces/customer-interface';
import { CreditsNotesResponseData } from '../../../../../administrative/credits-requests/interfaces/credits-notes-interface';
import { toggleLoader } from '../../../../helpers/functions-helper.service';
import { CreditRequestService } from '../../../../../administrative/credits-requests/services/credit-request.service';
import { CustomersService } from '../../../../../administrative/customers/services/customers.service';
import { LoaderService } from '../../../../services/loader.service';
import { ParametersService } from '../../../../services/parameters.service';
import { ModalServiceAlert } from '../../../modal-alert/service/modal-alert.service';
import { forkJoin } from 'rxjs';
import { IaExtractionComponent } from '../ia-extraction/ia-extraction.component';
import { Customer, CustomerData } from '../../../../../administrative/customers/interfaces/customer-credit-details.interface';

@Component({
      selector: 'app-personal-information-tab',
      templateUrl: './personal-information-tab.component.html',

      standalone: true,
      imports: [
            NgbModalModule,
            CommonModule,
            FormsModule,
            ReactiveFormsModule,
            IaExtractionComponent
      ]
})
export class PersonalInformationTabComponent {
      @Input() customerInfo!: CustomerData;
      @Input() datosDeshabilitados: boolean = true;
      @Input() creditRequestId!: number;
      isAdmin: boolean = false;
      customerEditForm!: FormGroup;
      cities: any = [];
      departments: any = [];
      ccis: any = [
            { id: 1, name: 'Si' },
            { id: 2, name: 'No' },
      ];
      typeIdentifications: any = [
            { id: 'CC', name: 'CC' },
            { id: 'CE', name: 'CE' },
      ];
      canSendWhatsapp: boolean = false;
      textareaContent!: string;
      notasAgregadas: CreditsNotesResponseData[] = [];

      constructor(
            public activeModal: NgbActiveModal,
            public CreditRequestService: CreditRequestService,
            public customerService: CustomersService,
            public formBuilder: FormBuilder,
            public loaderService: LoaderService,
            public localitationService: ParametersService,
            public modalService: NgbModal,
            public alertService: ModalServiceAlert
      ) {
      }

      /**
       * Inicializa el componente y configura el formulario del cliente.
       */
      ngOnInit() {
            console.log(this.customerInfo);
            console.log(this.creditRequestId);
            console.log(this.datosDeshabilitados);
            this.setFormCustomer();
            this.getRole();
            this.getData();
      }



      /**
       * Obtiene la información necesaria para el componente.
       */
      getData() {
            const departmentCityId = this.customerInfo?.city?.department?.id;


            const requests: any = {
                  departments: this.localitationService.getDepartments(),
                  notes: this.CreditRequestService.getCustomerNotes(this.creditRequestId),
            };

            if (departmentCityId) {
                  requests.cities = this.localitationService.getCitiesByDepartments(departmentCityId);
            }

            forkJoin(requests).subscribe({
                  next: (results: any) => {
                        this.departments = results.departments.data;
                        if (departmentCityId) this.cities = results.cities.data;
                        this.notasAgregadas = results.notes.data;
                        toggleLoader(this.loaderService, false);
                  },
                  error: (error) => {
                        toggleLoader(this.loaderService, false);
                        this.alertService.openModal('Error', 'Ocurrió un error al cargar la información. Por favor, inténtalo nuevamente.', 'error');
                  }
            });
      }

      /**
       * Obtiene el rol del usuario actual.
       */
      getRole() {
            const userString = localStorage.getItem('user');
            if (userString) {
                  const user = JSON.parse(userString);
                  this.isAdmin = user.role_id == '1';
            }
      }

      /**
       * Cambia el array de ciudades cuando se selecciona un nuevo departamento.
       */
      changeArrayCities() {
            this.customerEditForm.get('city_id')?.setValue(null);
            this.getCitiesByDepartments(this.customerEditForm.get('department_id')?.value);
      }

      /**
       * Envía un enlace de Deceval por WhatsApp al cliente.
       */
      sendWhatsappLinkDeceval() {
            toggleLoader(this.loaderService, true);
            this.customerService.sendWhatsappLinkDeceval(this.customerEditForm.value.id).subscribe((res: any) => {
                  toggleLoader(this.loaderService, false);
                  if (res.ok) {
                        this.alertService.openModal('Exito', res.message, 'success');
                  } else {
                        this.alertService.openModal('Error', res.message, 'error');
                  }
            })
      }

      /**
       * Alterna entre habilitar y deshabilitar el formulario de edición del cliente.
       */
      toggleEnableForm() {
            this.datosDeshabilitados = !this.datosDeshabilitados;
            if (this.datosDeshabilitados) {
                  this.customerEditForm.disable();
            } else {
                  this.customerEditForm.enable();
            }
      }

      /**
       * Actualiza la información del cliente en el servidor.
       */
      update() {
            if (this.customerEditForm.valid) {
                  toggleLoader(this.loaderService, true);
                  this.CreditRequestService.updateInfoCustomer(this.customerEditForm.value).subscribe(res => {
                        toggleLoader(this.loaderService, false);
                        if (res.ok) {
                              if (res.message && res.message.includes("Se enviará un nuevo enlace")) {
                                    this.canSendWhatsapp = true;
                              }
                              this.alertService.openModal('Éxito', 'Se actualizó la información del cliente.', 'success');
                        } else {
                              const campos = Object.keys(res.message);
                              let text = "faltan los campos: ";

                              if (campos.length > 1) {
                                    for (let i = 0; i < campos.length; i++) {
                                          if (i === campos.length - 1) {
                                                text = text + campos[i];
                                          } else {
                                                text = text + campos[i] + ", ";
                                          }
                                    }
                              }
                              this.alertService.openModal('Error', text, 'error');
                        }
                  });
            } else {
                  this.alertService.openModal('Error', 'Por favor, completa todos los campos.', 'error');
            }
      }

      /**
       * Obtiene las ciudades correspondientes a un departamento específico.
       * @param {number} departmentId - El ID del departamento.
       */
      getCitiesByDepartments(departmentId: number) {
            toggleLoader(this.loaderService, true);
            this.localitationService.getCitiesByDepartments(departmentId).subscribe((res: any) => {
                  toggleLoader(this.loaderService, false);
                  this.cities = res.data;
            });
      }

      /**
       * Guarda una nueva nota para el cliente.
       */
      saveNotes() {
            const creditRequestId = this.creditRequestId;
            const valueNote = this.textareaContent;
            toggleLoader(this.loaderService, true);
            this.CreditRequestService.saveCustomerNote(creditRequestId, valueNote).subscribe(
                  (response) => {
                        toggleLoader(this.loaderService, false);
                        if (response.ok) {
                              this.getNotesCredits();
                              this.alertService.openModal('Éxito', 'Se agrego una nueva nota.', 'success');
                        } else {
                              toggleLoader(this.loaderService, false);
                              this.alertService.openModal(
                                    'Error',
                                    'No se guardo la nota intenta nuevamente.',
                                    'error'
                              );
                        }
                  }
            );
      }

      /**
       * Obtiene las notas de crédito del cliente.
       */
      getNotesCredits() {
            toggleLoader(this.loaderService, true);
            const customerId = this.creditRequestId;
            this.CreditRequestService.getCustomerNotes(customerId).subscribe(
                  (response) => {
                        toggleLoader(this.loaderService, false);
                        if (response.ok) {
                              this.notasAgregadas = response.data;
                        } else {
                              this.alertService.openModal(
                                    'Error',
                                    'No se pudo obtener las notas. Inténtalo nuevamente.',
                                    'error'
                              );
                        }
                  },
                  (error) => {
                        toggleLoader(this.loaderService, false);
                        this.alertService.openModal(
                              'Error',
                              'Ocurrió un error al obtener las notas. Por favor, inténtalo nuevamente.',
                              'error'
                        );
                  }
            );
      }

      /**
       * Configura el formulario de edición del cliente con los datos actuales.
       */
      setFormCustomer() {

            this.customerEditForm = this.formBuilder.group({
                  id: [this.customerInfo.customer.id, Validators.required],
                  nit: [this.customerInfo.customer.nit, Validators.required],
                  buss_name: [this.customerInfo.customer.business_name, Validators.required],

                  department_id: [this.customerInfo.city?.department?.id ? this.customerInfo.city?.department?.id : null, Validators.required],




                  city_id: [this.customerInfo.city?.id ? this.customerInfo.city?.id : null, Validators.required],
                  businness_address: [this.customerInfo.customer.businness_address, Validators.required],
                  registered_chamber_commerce: [this.customerInfo.customer.registered_chamber_commerce, Validators.required],
                  name: [this.customerInfo.customer.name, Validators.required],




                  last_name: [this.customerInfo.customer.last_name, Validators.required],
                  identification_type: [this.customerInfo.customer.identification_type, Validators.required],
                  identification: [this.customerInfo.customer.identification, Validators.required],
                  phone: [this.customerInfo.customer.phone, Validators.required],
                  phone_2: [this.customerInfo.customer.phone2, Validators.required],



                  email: [this.customerInfo.customer.email, Validators.required],
                  address: [this.customerInfo.customer.address, Validators.required],
                  date_of_birth: [this.customerInfo.customer.date_of_birth, Validators.required],
                  emission_date: [this.customerInfo.customer.emission_date, Validators.required],


            });
      }

      /**
       * Obtiene y parsea la descripción de IA para un campo específico del documento del cliente
       *
       * @param {keyof Pick<CustomerInterface, 'descripcion_ia_document_file_up' | 'descripcion_ia_document_file_down' | 'descripcion_ia_image_file'>} field
       *        El campo del documento del cliente del cual se quiere obtener la descripción de IA
       *
       * @returns {IADescription | null} Retorna un objeto IADescription con la información parseada si es exitoso,
       *          o null si ocurre un error durante el parsing
       *
       * @throws {Error} Si hay un error al parsear el JSON del campo, se captura y se retorna null
       *
       * @example
       * // Obtener descripción IA del frente del documento
       * const frontDescription = getIADescription('descripcion_ia_document_file_up');
       */
      getIADescription(
            field: keyof Pick<
              Customer,
              | 'descripcion_ia_document_file_up'
              | 'descripcion_ia_document_file_down'
              | 'descripcion_ia_image_file'
            >
          ): IADescription | null {
            try {
              return JSON.parse(this.customerInfo.customer[field]);
            } catch (error) {
              console.error(`Error parsing ${field}:`, error);
              return null;
            }
          }

}
